import React from "react";
import Faculty1 from "../../assets/images/event-details/Faculty/Rajeev-Mehrotra.jpg";
import Faculty2 from "../../assets/images/event-details/Faculty/B-letter.jpg";
import Faculty3 from "../../assets/images/event-details/Faculty/Rama-Raju.jpg";
import Faculty4 from "../../assets/images/event-details/Faculty/Harsh-M.A.(Global-Thought)-University-of-Columbia.jpg";
import Faculty5 from "../../assets/images/event-details/Faculty/ShagunSethi.jpg";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import './PeopleBehindUs.css'
import Col from "react-bootstrap/Col";



export default function Faculty() {
    return (

        <Row className="mt-5 doml">
        <Col sm={12} md={3}>
          <Card className="box-sdow">
            <Card.Img variant="top" src={Faculty1} />
            <Card.Body className="pb-0">
              <Card.Title>Rajeev Mehrotra</Card.Title>
              <Card.Text className="m-0"> Senior Fellow </Card.Text>
            </Card.Body>
           </Card>
        </Col>

        <Col sm={12} md={3}>
          <Card className="box-sdow">
            <Card.Img variant="top" src={Faculty2} />
            <Card.Body className="pb-0">
              <Card.Title>Benetto Jos</Card.Title>
              <Card.Text className="m-0"> Senior Fellow </Card.Text>
            </Card.Body>
           </Card>
        </Col>

        <Col sm={12} md={3}>
          <Card className="box-sdow">
            <Card.Img variant="top" src={Faculty3} />
            <Card.Body className="pb-0">
              <Card.Title> Rama Raju </Card.Title>
              <Card.Text className="m-0"> Senior Fellow, Andhra Pradesh </Card.Text>
            </Card.Body>
           </Card>
        </Col>

        <Col sm={12} md={3}>
          <Card className="box-sdow">
            <Card.Img variant="top" src={Faculty4} />
            <Card.Body className="pb-0">
              <Card.Title> Harsh </Card.Title>
              <Card.Text className="m-0"> Associate Fellow </Card.Text>
            </Card.Body>
           </Card>
        </Col>

        <Col sm={12} md={3} className="pt-4">
          <Card className="box-sdow">
            <Card.Img variant="top" src={Faculty5} />
            <Card.Body className="pb-0">
              <Card.Title> Shagun Sethi </Card.Title>
              <Card.Text className="m-0"> Associate Fellow </Card.Text>
            </Card.Body>
           </Card>
        </Col>
        
      </Row>
)
}